<template>
	<div class="container-fluid">
		<!-- <post :author="{ id:'1234', username:'john'}"
			title="Test post"
			content="This is a test post that I manually wrote. Need to add a for loop still."
			createdAt="2021-11-25T02:20:09.118Z"
			:comments="[]"
			:upvotes="1"
		>
		</post> -->
		<div class="row"  v-for="post in posts" :key="post.id">
			<post class="col-lg-6 col-md-8 col-sm-10 col-xs-12 mx-auto"
				:authorId="post.author"
				:id="post.id"
				:title="post.title"
				:content="post.content"
				:createdAt="post.createdAt"
				:comments="post.comments"
				:upvotes="post.upvotes"
				:upvoteList="upvotes"
			>
			</post>
		</div>
	</div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";

const { mapState: mapAccountState } = createNamespacedHelpers("account");

export default {
	props: {
		posts: {
			type: Array,
			required: true
		}
	},
	computed: {
		...mapAccountState(["upvotes"])
	}
};
</script>
